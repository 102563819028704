/* eslint-disable camelcase */
import React from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

import styled from 'styled-components'
import {formatMoney} from '../../../utils'

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 40px;
  align-items: center;
  grid-gap: 30px;
  border-bottom: 1px solid ${props => props.theme.grey5};
  padding: 20px;
  .ui.basic.button {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    &.remove:hover {
      background: ${props => props.theme.red} !important;
      color: #fff !important;
    }
  }
  .img {
    box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
    border: 1px solid ${props => props.theme.grey5};
    line-height: 0;
    min-height: 50px;
  }
  .title {
    color: ${props => props.theme.grey2};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
  }
  .meta {
    color: rgba(0, 0, 0, 0.4);
    margin-top: 6px;
  }
  &.promo {
    .free {
      background-color: #60c4e8;
      color: #fff;
      padding: 1px 5px;
      border-radius: 3px;
    }
  }
  @media (max-width: 480px) {
    .img {
      grid-column: 1 / span 2;
    }
    grid-template-columns: 1fr 40px;
  }
  .sku {
    background-color: #bebecb;
    color: #fff;
    padding: 1px 5px;
    border-radius: 3px;
  }
`

const CartItemList = ({items, removeFromCart}) => (
  <>
    {items.map(item => {
      const price = item.line_subtotal || 0
      const imageUrl = item.product_image || '/static/Kooi-icon-blue-small.png'

      // Regular cart items
      if (price !== 0) {
        return (
          <ItemRow key={item.key}>
            <div className="img">
              <img src={imageUrl} alt={item.product_name} />
            </div>
            <div>
              <div className="title">{item.product_name}</div>
              <div className="meta">
                {`${item.quantity} x  ${item.product_price} `}
              </div>
              {/* <span className="sku">{item.sku}</span> */}
            </div>
            {}
            <div>
              <Button
                size="small"
                basic
                icon="remove"
                className="remove"
                onClick={() => removeFromCart(item, item.quantity)}
              />
            </div>
          </ItemRow>
        )
      }
      // PROMO cart items
      return (
        <ItemRow key={item.key} className="promo">
          <div className="img">
            <img src={imageUrl} alt={item.product_name} />
          </div>
          <div>
            <div className="title">{item.product_name}</div>
            <div className="meta">
              {item.quantity} x <span className="free">FREE</span>
            </div>
          </div>
          <div>
            <Button
              size="small"
              basic
              icon="remove"
              className="remove"
              onClick={() => removeFromCart(item, item.quantity)}
            />
          </div>
        </ItemRow>
      )
    })}
  </>
)
export default CartItemList
