import React from 'react'

const IconCheck = ({fill}) => {
  return (
    <div className="custom-icon">
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Menu"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M14.9999648,0.218811467 C23.0253439,0.218811467 29.5311802,6.7246477 29.5311802,14.7500268 C29.5311802,22.7754059 23.0253439,29.2812422 14.9999648,29.2812422 C6.97458568,29.2812422 0.468749449,22.7754059 0.468749449,14.7500268 C0.468749449,6.7246477 6.97458568,0.218811467 14.9999648,0.218811467 Z M14.9999648,27.4062466 C22.0279937,27.4062466 27.6561846,21.7029254 27.6561846,14.7500268 C27.6561846,7.72199792 21.9529206,2.093807 14.9999648,2.093807 C7.97193591,2.093807 2.34374498,7.79712821 2.34374498,14.7500268 C2.34374498,21.7780557 8.04700897,27.4062466 14.9999648,27.4062466 Z M23.298571,11.2952926 L12.7236259,21.7854371 C12.4479384,22.0588931 12.0027071,22.0571192 11.7292512,21.7814317 L6.69735322,16.7088501 C6.4238973,16.4331626 6.42567113,15.9879885 6.70135864,15.7145326 L7.20054874,15.2193479 C7.47623625,14.945892 7.92146758,14.9476658 8.1949235,15.2233533 L12.2364521,19.2975547 L21.8130742,9.79777957 C22.0887617,9.52432364 22.5339358,9.52604025 22.8073917,9.80172777 L23.3025764,10.3009751 C23.5760895,10.5766626 23.5742585,11.0218367 23.298571,11.2952926 Z"
            id=""
            fill={fill || '#31D38E'}
            fillRule="nonzero"
          />
        </g>
      </svg>
    </div>
  )
}
export default IconCheck
