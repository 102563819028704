import React from 'react'

const IconShoppingCart = ({fill}) => {
  return (
    <div className="custom-icon">
      <svg
        width="37px"
        height="33px"
        viewBox="0 0 37 33"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Menu"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M35.4577025,4.11111111 C36.4356077,4.11111111 37.1664175,5.00985209 36.9670601,5.96718343 L34.1836469,19.3282945 C34.034662,20.0432349 33.4045973,20.5555556 32.674352,20.5555556 L11.3877741,20.5555556 L12.158606,24.6666667 L31.1755331,24.6666667 C31.9098559,24.6666667 32.5048543,25.1735297 32.6712782,25.8313853 C33.4390991,26.5783183 33.9166661,27.6219669 33.9166661,28.7777778 C33.9166661,31.0482483 32.0760289,32.8888889 29.8055626,32.8888889 C27.5350963,32.8888889 25.6944592,31.0482483 25.6944592,28.7777778 L25.6944592,28.7732612 C25.6944592,28.1662801 25.941429,27.2474026 26.2457972,26.7222222 L16.9208516,26.7222222 C17.2252198,27.2474026 17.4721896,28.1662801 17.4721896,28.7732612 L17.4721896,28.7777778 C17.4721896,31.0482483 15.6315524,32.8888889 13.3610862,32.8888889 C11.0906199,32.8888889 9.24998272,31.0482483 9.24998272,28.7777778 C9.24998272,27.712738 9.65497057,26.7424215 10.3193489,26.0121748 L5.82747155,2.05555556 L0.770831894,2.05555556 C0.345142991,2.05555556 -4.54747351e-13,1.71041192 -4.54747351e-13,1.28472222 L-4.54747351e-13,0.770833333 C-4.54747351e-13,0.345143636 0.345142991,0 0.770831894,0 L6.25416414,0 C6.99601456,0 7.632666,0.528379652 7.76941841,1.25755988 L8.3044465,4.11111111 L35.4577025,4.11111111 Z M15.4166667,28.7777778 C15.4166667,27.6443617 14.4945272,26.7222222 13.3611111,26.7222222 C12.227695,26.7222222 11.3055556,27.6443617 11.3055556,28.7777778 C11.3055556,29.9111938 12.227695,30.8333333 13.3611111,30.8333333 C14.4945272,30.8333333 15.4166667,29.9111938 15.4166667,28.7777778 Z M29.8055556,30.8333333 C30.9389716,30.8333333 31.8611111,29.9111938 31.8611111,28.7777778 C31.8611111,27.6443617 30.9389716,26.7222222 29.8055556,26.7222222 C28.6721395,26.7222222 27.75,27.6443617 27.75,28.7777778 C27.75,29.9111938 28.6721395,30.8333333 29.8055556,30.8333333 Z M32.4184222,18.5 L34.9444444,6.16666667 L9.25,6.16666667 L11.52342,18.5 L32.4184222,18.5 Z"
            id="icon-cart"
            fill={fill || '#73718E'}
            fillRule="nonzero"
          />
        </g>
      </svg>
    </div>
  )
}
export default IconShoppingCart
