// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React, {useState, useEffect} from 'react'
import {navigate} from 'gatsby'
import {loadStripe} from '@stripe/stripe-js'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import {formatMoney} from '../../../utils'

// The styles provided by the Stripe example. Yuck. Needs lots of work
import './card-styles.css'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#202020',
      color: '#202020',
      fontFamily: 'monospace',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#202020',
      },
      '::placeholder': {
        color: '#c0c0c0',
      },
    },
    invalid: {
      iconColor: '#ff043a',
      color: '#ff043a',
    },
  },
}

const CardField = ({isMobile = true, onChange}) => (
  <>
    <p className="FormHeading">Card Details:</p>
    <div className="FormRow">
      <label htmlFor={'CardNumberElement'} className="FormRowLabel">
        Number
      </label>
      <CardNumberElement
        id={'CardNumberElement'}
        options={CARD_OPTIONS}
        onChange={onChange}
      />
    </div>
    <div className="FormRow">
      <label htmlFor={'CardExpiryElement'} className="FormRowLabel">
        Expiry
      </label>
      <CardExpiryElement
        id={'CardExpiryElement'}
        options={CARD_OPTIONS}
        onChange={onChange}
      />
    </div>
    <div className="FormRow">
      <label htmlFor={'CardCvcElement'} className="FormRowLabel">
        CVC
      </label>
      <CardCvcElement
        id={'CardCvcElement'}
        options={CARD_OPTIONS}
        onChange={onChange}
      />
    </div>
  </>
)

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
)

const SubmitButton = ({processing, error, children, disabled}) => (
  <button
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </button>
)

const ErrorMessage = ({children}) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#ff043a"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
)

const CheckoutForm = ({setLoading, paymentData, orderData}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isMobile, setMobile] = useState(false)
  const [error, setError] = useState(null)
  const [apiError, setApiError] = useState(null)
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [billingDetails, setBillingDetails] = useState({
    email: paymentData.email,
    phone: paymentData.phone,
    name: paymentData.name,
  })

  const updateDimensions = () => {
    if (window.innerWidth < 600) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    updateDimensions()
  }, [])

  useEffect(() => {
    if (orderData && paymentData) {
      setLoading(false)
    }
  }, [orderData, paymentData])

  const handleSubmit = async event => {
    setApiError(null)
    event.preventDefault()

    if (!stripe || !elements || !paymentData || !orderData) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement('card').focus()
      return
    }

    if (cardComplete) {
      setLoading(true)
      setProcessing(true)
    }

    const result = await stripe.confirmCardPayment(paymentData.client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: billingDetails,
      },
      receipt_email: paymentData.email,
      shipping: {
        name: `${orderData.shipping.first_name} ${orderData.shipping.last_name}`,
        phone: orderData.billing.phone,
        address: {
          line1: orderData.shipping.address_1,
          line2: orderData.shipping.address_2,
          city: orderData.shipping.city,
          postal_code: orderData.shipping.postcode,
          state: orderData.shipping.state,
          country: orderData.shipping.country,
        },
      },
    })

    setProcessing(false)
    setLoading(false)

    if (result && result.error) {
      setApiError(result.error)
    } else if (result && result.paymentIntent) {
      if (result.paymentIntent.status === 'succeeded') {
        navigate(`/order-success?orderid=${paymentData.order_id}`)
      } else {
        setApiError({
          message: `Oh no! Your payment status is ${result.paymentIntent.status}. Please contact our support staff`,
        })
      }
    } else {
      setApiError({
        message:
          'Oh no! Something went wrong trying to process your payment. Please try again or contact our support',
      })
    }
  }

  return (
    <form className="Form" onSubmit={e => handleSubmit(e, isMobile)}>
      <fieldset className="FormGroup">
        {isMobile && <p className="FormHeading">Card Holder Details:</p>}
        <Field
          onFocus={() => {
            setApiError(null)
          }}
          label="Name"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={e => {
            setBillingDetails({...billingDetails, name: e.target.value})
          }}
        />
        <Field
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={e => {
            setApiError(null)
            setBillingDetails({...billingDetails, email: e.target.value})
          }}
        />
        <Field
          label="Phone"
          id="phone"
          type="tel"
          placeholder="(941) 555-0123"
          required
          autoComplete="tel"
          value={billingDetails.phone}
          onChange={e => {
            setApiError(null)
            setBillingDetails({...billingDetails, phone: e.target.value})
          }}
        />
      </fieldset>
      <fieldset className="FormGroup">
        <CardField
          isMobile={isMobile}
          onChange={e => {
            setApiError(null)
            setError(e.error)
            setCardComplete(e.complete)
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      {apiError && apiError.message != error.message && (
        <ErrorMessage>{apiError.message}</ErrorMessage>
      )}
      <SubmitButton
        processing={processing || !stripe}
        error={error || apiError}
        disabled={!stripe || !elements || !paymentData || !orderData}
      >
        Pay {formatMoney(paymentData.amount / 100)}
      </SubmitButton>
    </form>
  )
}

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const Stripe = ({setLoading, paymentData, orderData}) => {
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm
          setLoading={setLoading}
          paymentData={paymentData}
          orderData={orderData}
        />
      </Elements>
    </div>
  )
}

export default Stripe
