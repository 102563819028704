import React, {useState, useEffect, useContext} from 'react'

const FreeShippingSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{
        enableBackground: 'new 0 0 512 512',
        maxWidth: '50px',
        maxHeight: '50px',
      }}
      xmlSpace="preserve"
      width="512px"
      height="512px"
      className={''}
    >
      <g>
        <path
          style={{fill: '#FFFFFF'}}
          d="M256,512c141.392,0,256-114.608,256-256S397.392,0,256,0S0,114.608,0,256S114.608,512,256,512z"
          data-original="#E2E2E2"
          className={'active-path'}
          data-old_color="#E2E2E2"
        />
        <g>
          <path
            style={{fill: '#FFFFFF'}}
            d="M235.152,394.128c14.576,0,26.416-11.76,26.624-26.272h-53.248   C208.752,382.384,220.576,394.128,235.152,394.128z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M78,394.128c14.576,0,26.416-11.76,26.624-26.272H51.376C51.6,382.384,63.44,394.128,78,394.128z"
            data-original="#FFFFFF"
            className={''}
          />
          <circle
            style={{fill: '#FFFFFF'}}
            cx="424.496"
            cy="367.472"
            r="26.72"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M360.528,178.528v165.328h19.392l0,0c8.512-16.032,25.168-27.072,44.576-27.072   s36.064,11.04,44.576,27.072l0,0h18.752v-73.552l-57.344-91.792L360.528,178.528L360.528,178.528z M379.184,233.392V196.64h40.32   l20,36.752H379.184z"
            data-original="#FFFFFF"
            className={''}
          />
        </g>
        <path
          d="M261.776,367.872c-0.224,14.512-12.064,26.272-26.624,26.272s-26.4-11.76-26.624-26.272h-24  c0.224,27.792,22.784,50.272,50.624,50.272s50.416-22.48,50.624-50.272H261.776z"
          data-original="#000000"
          className={''}
          style={{fill: '#505061'}}
          data-old_color="#000000"
        />
        <path
          d="M104.624,367.872C104.4,382.384,92.56,394.144,78,394.144s-26.416-11.76-26.624-26.272h-24  C27.6,395.664,50.16,418.144,78,418.144s50.416-22.48,50.624-50.272H104.624z"
          data-original="#000000"
          className={''}
          style={{fill: '#505061'}}
          data-old_color="#000000"
        />
        <path
          d="M336.528,154.528v213.328h37.344c0-0.128-0.048-0.256-0.048-0.4c0,27.984,22.688,50.672,50.672,50.672  s50.672-22.688,50.672-50.672c0,0.128-0.032,0.256-0.048,0.4h36.704V263.424l-68.032-108.912L336.528,154.528L336.528,154.528z   M487.84,343.872h-18.752l0,0c-8.512-16.032-25.168-27.072-44.576-27.072s-36.064,11.04-44.576,27.072l0,0h-19.392V178.528h69.968  l57.344,91.792v73.552H487.84z M424.496,394.176c-14.752,0-26.72-11.952-26.72-26.72s11.968-26.72,26.72-26.72  s26.72,11.952,26.72,26.72S439.248,394.176,424.496,394.176z"
          data-original="#000000"
          className={''}
          style={{fill: '#505061'}}
          data-old_color="#000000"
        />
        <polygon
          points="379.184,196.624 379.184,233.392 439.504,233.392 419.504,196.624 "
          data-original="#000000"
          className={''}
          style={{fill: '#505061'}}
          data-old_color="#000000"
        />
        <path
          style={{fill: '#F9053B'}}
          d="M0.16,367.872h27.216h24h53.248h24h55.888h24h53.248h24h26.592v-274H0.16V367.872z"
          data-original="#ED1C24"
          className={''}
          data-old_color="#ED1C24"
        />
        <g>
          <path
            style={{fill: '#FFFFFF'}}
            d="M36.256,159.024h47.2v12.256H51.2v22.576h30.08v12.256H51.2v34.704H36.256V159.024z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M94.928,160.112c5.6-0.976,13.952-1.696,22.576-1.696c11.76,0,19.776,1.936,25.232,6.544   c4.48,3.76,7.024,9.472,7.024,16.384c0,10.544-7.152,17.712-14.8,20.496v0.368c5.824,2.176,9.344,7.872,11.408,15.76   c2.544,10.192,4.736,19.664,6.432,22.816h-15.408c-1.2-2.416-3.152-9.088-5.456-19.296c-2.32-10.672-6.192-14.08-14.688-14.32   h-7.52v33.616h-14.8V160.112z M109.744,196.272h8.864c10.064,0,16.368-5.344,16.368-13.472c0-8.992-6.32-13.104-16.016-13.104   c-4.736,0-7.76,0.368-9.216,0.736V196.272z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M209.664,204.512h-30.816v24.032h34.448V240.8h-49.392v-81.776h47.568v12.256h-32.624v21.12h30.816   C209.664,192.4,209.664,204.512,209.664,204.512z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M272.08,204.512h-30.816v24.032h34.448V240.8H226.32v-81.776h47.568v12.256h-32.624v21.12h30.816   C272.08,192.4,272.08,204.512,272.08,204.512z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M37.424,293.232c2.352,1.36,5.888,2.528,9.6,2.528c4.64,0,7.232-2.176,7.232-5.408   c0-3.008-2-4.768-7.056-6.592c-6.528-2.336-10.704-5.824-10.704-11.536c0-6.464,5.408-11.408,14-11.408   c4.304,0,7.472,0.944,9.536,2l-1.76,5.808c-1.408-0.768-4.176-1.872-7.888-1.872c-4.576,0-6.576,2.464-6.576,4.768   c0,3.056,2.304,4.464,7.6,6.528c6.88,2.592,10.24,6.064,10.24,11.76c0,6.352-4.832,11.888-15.056,11.888   c-4.192,0-8.528-1.184-10.72-2.464L37.424,293.232z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M75.36,261.472v15.888h16.768v-15.888h7.232v39.664h-7.232v-17.472H75.36v17.472h-7.248v-39.664   H75.36z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M114.864,261.472v39.664h-7.248v-39.664H114.864z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M123.152,262c2.592-0.464,6.192-0.832,11.056-0.832c5.296,0,9.184,1.12,11.712,3.232   c2.336,1.952,3.872,5.056,3.872,8.768c0,3.76-1.168,6.896-3.408,8.992c-2.896,2.896-7.424,4.256-12.528,4.256   c-1.36,0-2.592-0.064-3.536-0.24v14.944h-7.168V262z M130.336,280.48c0.88,0.24,2.064,0.304,3.536,0.304   c5.408,0,8.704-2.64,8.704-7.296c0-4.4-3.056-6.768-8.064-6.768c-2,0-3.408,0.176-4.176,0.352V280.48z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M156,262c2.592-0.464,6.192-0.832,11.056-0.832c5.296,0,9.184,1.12,11.712,3.232   c2.336,1.952,3.872,5.056,3.872,8.768c0,3.76-1.168,6.896-3.408,8.992c-2.896,2.896-7.424,4.256-12.528,4.256   c-1.36,0-2.592-0.064-3.536-0.24v14.944H156V262z M163.2,280.48c0.88,0.24,2.064,0.304,3.536,0.304c5.408,0,8.704-2.64,8.704-7.296   c0-4.4-3.056-6.768-8.064-6.768c-2,0-3.408,0.176-4.176,0.352V280.48z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M196.112,261.472v39.664h-7.248v-39.664H196.112z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M204.4,301.136v-39.664h8.256l10.24,16.992c2.64,4.416,4.944,8.992,6.768,13.296h0.112   c-0.464-5.296-0.64-10.4-0.64-16.464v-13.824h6.704v39.664h-7.472l-10.352-17.424c-2.528-4.336-5.168-9.232-7.104-13.696   l-0.192,0.064c0.304,5.104,0.352,10.336,0.352,16.88v14.192L204.4,301.136L204.4,301.136z"
            data-original="#FFFFFF"
            className={''}
          />
          <path
            style={{fill: '#FFFFFF'}}
            d="M275.904,299.296c-2.528,0.96-7.472,2.24-12.768,2.24c-6.72,0-11.76-1.696-15.536-5.296   c-3.536-3.344-5.6-8.576-5.536-14.576c0-12.528,8.944-20.592,22.064-20.592c4.88,0,8.704,0.992,10.528,1.872l-1.6,5.824   c-2.176-0.944-4.832-1.696-8.992-1.696c-8.464,0-14.416,4.992-14.416,14.24c0,8.992,5.536,14.352,13.76,14.352   c2.592,0,4.528-0.352,5.488-0.832v-9.888h-7.008v-5.696h14L275.904,299.296L275.904,299.296z"
            data-original="#FFFFFF"
            className={''}
          />
        </g>
      </g>
    </svg>
  )
}

export default FreeShippingSvg
