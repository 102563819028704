/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, {useState, useContext, useEffect} from 'react'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

import styled from 'styled-components'
import {useStaticQuery, graphql, Link} from 'gatsby'
import Layout from '../components/Layout'
import CheckoutTabs from '../components/Checkout/CheckoutTabs'
import Payment from '../components/Checkout/Payment'
import CartItemList from '../components/Checkout/CartItemList'
import CartAccessoriesList from '../components/Checkout/CartAccessoriesList'
import FreeShippingSvg from '../components/Checkout/FreeShippingSvg'
import AddressInfo from '../components/AddressInfo'
import LoadModal from '../components/LoadModal'
import RegisterForm from '../components/RegisterForm'
import EllipsisLoader from '../components/EllipsisLoader'
import CartContext from '../components/Context/CartContext'
import AuthContext from '../components/Context/AuthContext'
import {isLoggedIn, getUser} from '../services/auth'
import {formatMoney, scrollToTop} from '../utils'
import {
  analyticsGoToCart,
  analyticsCartStepShipping,
  analyticsCartStepPayment,
  analyticsPurchase,
  analyticsAddToCart,
  analyticsRemoveFromCart,
} from '../services/GoogleAnalytics'
import {
  pixelAddToCart,
  pixelGoToCart,
  pixelPurchase,
} from '../services/FacebookPixel'
import {createOrder} from '../utils/WordPress'

const StyledCenterButton = styled.div`
  display: inline-block;
  width: 100%;
`
const StyledLink = styled(Link)`
  background: ${props => props.theme.red};
  color: ${props => props.theme.white};
  margin: 0px auto 20px auto;
  display: block;
  width: 250px;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  border-radius: 50px;
  transition: all 200ms ease-in-out;
  font-weight: bold;
  &:hover {
    color: ${props => props.theme.white};
    background: ${props => props.theme.redHover};
  }
`

export const CartPageStyles = styled.div`
  padding: 20px 0 117px 0px;
  background: ${props => props.theme.white};
  .cart-inner-container > div.checkoutCartSummaryFooter {
    padding: 20px;
  }
  .cart-inner-container > div {
    padding: 0 20px;
  }
  .cart-inner-container > p {
    padding: 20px 20px 0px 20px;
  }
  .cart-inner-container.not-logged-in {
    .message.info {
      height: 43px;
      margin: 20px;
      padding-left: 40px;
      padding-top: 10px;
    }
    .login-message-row {
      padding-top: 30px;
      height: 80px;
    }
  }
  .checkout-button {
    text-align: center;
  }
  @media (max-width: 1200px) {
    padding-top: 0;
  }
`
export const Inner = styled.div`
  background: ${props => props.theme.white};
  max-width: 1200px;
  margin: 0 auto;
  padding: 1px 0 0 0;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
  transition: all 300ms ease-in-out;
  &.cart-empty-padding {
    padding-bottom: 1px;
  }
  .p-text-align-center {
    text-align: center;
  }
  h2 {
    margin: 0 !important;
    padding: 20px;
    background: ${props => props.theme.grey1};
    color: ${props => props.theme.white};
    text-align: left;
    &.top {
      border-radius: 5px 5px 0 0;
    }
  }
  &.cart-page h2 {
    text-align: center;
  }
  &.cart-page h3 {
    margin: 0 !important;
    padding: 20px;
    text-align: center;
    background: transparent;
    color: ${props => props.theme.black};
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #e1e7ed;
  }
  .payment-continue {
    text-align: center;
    padding: 10px;
  }
  .ui.active.loader {
    margin: 50px;
  }
  .message.info {
    margin-top: 20px !important;
    &.flush {
      margin: 20px 20px;
    }
  }
  .login-message-row {
    text-align: center;
    padding: 40px 0 20px 0;
    a {
      color: ${props => props.theme.red};
      border: 2px solid ${props => props.theme.red};
      padding: 10px;
      margin: 10px;
      border-radius: 50px;
      text-transform: uppercase;
      transition: all 300ms ease-in-out;
      &:hover {
        color: ${props => props.theme.white};
        background: ${props => props.theme.red};
      }
    }
  }
  .cart-message-inner {
    text-align: center;
    padding: 20px;
    h3 {
      text-transform: unset;
      .lock {
        display: inline;
        padding: 0 15px;
        position: relative;
        top: 4px;
      }
    }
    .logos {
      padding: 20px;
      text-align: center;
      img {
        width: 100%;
        max-width: 350px;
      }
    }
  }
  @media (min-width: 1024px) {
    .accessory-table {
      width: 100%;
      display: table;
    }
    .accessory-row {
      display: table-row;
    }
    .accessory-cell {
      display: table-cell;
    }
    .accessory-cell-left.accessory-cell-in-2-col {
      border-right: 1px solid #e1e7ed;
    }
    .accessory-cell-in-2-col {
      width: 50%;
    }
    .accessory-cell-in-1-col {
      width: 100%;
    }
  }
  @media (max-width: 1200px) {
    padding: 0;
    h2.top {
      border-radius: 0;
    }
  }
`
export const CartSummaryFooter = styled.div`
  background: ${props => props.theme.grey1};
  margin: 0 0 -1px 0; /*fixes Inner padding*/
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 15px;
  .text {
    color: #fff;
    font-size: 20px;
    justify-self: flex-end;
  }
  .ui.button {
    background: ${props => props.theme.red};
    border-radius: 50px;
    border: 2px solid ${props => props.theme.red};
    text-transform: uppercase;
    color: #fff;
    &:hover {
      background: ${props => props.theme.redHover};
    }
  }
  .action {
    justify-self: flex-start;
  }
  @media (max-width: 1200px) {
    margin-bottom: 0;
    border-radius: 0;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    .text {
      justify-self: center;
    }
    .action {
      justify-self: center;
    }
  }
`

const Cart = ({location}) => {
  const specialProductsQuery = useStaticQuery(graphql`
    {
      promoProduct: wcProducts(
        attributes: {
          elemMatch: {
            name: {eq: "PromotionalWith"}
            options: {eq: "Mattresses"}
          }
        }
      ) {
        wordpress_id
      }
      mattressProducts: allWcProducts(
        filter: {
          categories: {elemMatch: {name: {eq: "Mattresses"}}}
          catalog_visibility: {eq: "visible"}
        }
      ) {
        edges {
          node {
            wordpress_id
          }
        }
      }
    }
  `)
  const [loading, setLoading] = useState(true)
  const [checkoutError, setCheckoutError] = useState('')
  const [processingModal, setProcessingModal] = useState(false)
  const [orderId, setOrderId] = useState(null)
  const [order, setOrder] = useState(null)
  const [errorInfo, setErrorInfo] = useState([])
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false)
  const {performLogout, loggedInState} = useContext(AuthContext)

  const {
    forceUseCurrentCart,
    cartState,
    setCartState,
    addToCart,
    removeFromCart,
    setSpecialProducts,
    getCartContents,
    getCartMeta,
    cartContents,
    cartMeta,
    cartReady,
    isOnCartPage,
    allProductsQueryData,
  } = useContext(CartContext)

  // Perform when cartContents is populated
  useEffect(() => {
    scrollToTop()
    setLoading(false)
  }, [cartContents])

  // Only perform once on page load
  useEffect(() => {
    getCartContents()
    scrollToTop()
    setSpecialProducts(specialProductsQuery)
    // If URL contains an orderid parameter, jump straight to payment
    if (location.search.includes('?orderid')) {
      setCartState('billing')
    } else if (cartState === 'billing') {
      setCartState('cart')
    } else if (cartContents) {
      analyticsGoToCart(cartContents)
      pixelGoToCart(cartContents)
    }
  }, [])

  useEffect(() => {
    // Check if script has been loaded to avoid multiples
    if (!googleScriptLoaded) {
      const oldScript = document.getElementById('google_customer_review_script')
      let body = document.getElementsByTagName('body')[0]
      if (oldScript) {
        setGoogleScriptLoaded(true)
      } else {
        var newScript = document.createElement('script')
        newScript.type = 'text/javascript'
        newScript.async = true
        newScript.defer = true
        newScript.src = `https://apis.google.com/js/platform.js`
        newScript.id = 'google_customer_review_script'
        newScript.addEventListener('load', () => setGoogleScriptLoaded(true))
        body.appendChild(newScript)
      }
    }
  }, [])

  //  Confirm order, ready to be paid
  const confirmOrder = async () => {
    setProcessingModal(true)
    const user = getUser()

    try {
      // Create order from our cart
      const orderResponse = await createOrder(
        cartMeta,
        user.token,
        performLogout,
      )
      if (orderResponse.id) {
        // Successfull order placed
        setOrder(orderResponse)

        const orderLineItems = orderResponse.line_items
        for (const lineItem of orderLineItems) {
          lineItem.categories = []
          if (allProductsQueryData && allProductsQueryData.length > 0) {
            for (const productsQueryData of allProductsQueryData) {
              if (productsQueryData.wordpress_id === lineItem.product_id) {
                lineItem.categories = productsQueryData.categories
                break
              }
            }
          } else if (cartContents) {
            for (const cartItem of cartContents) {
              if (cartItem.product_id === lineItem.product_id) {
                if (cartItem.categories) {
                  lineItem.categories = cartItem.categories
                }
                break
              }
            }
          }
        }
        analyticsPurchase(orderResponse, orderLineItems)
        pixelPurchase(orderResponse, orderLineItems)
        setCheckoutError('')
        continueButton()
      } else if (orderResponse.message) {
        try {
          if (orderResponse.message.errors)
            setCheckoutError(orderResponse.message.errors['out-of-stock'][0])
          if (orderResponse.message) setCheckoutError(orderResponse.message)
        } catch {
          setCheckoutError(
            'Oh no! Something went wrong while placing your order... Please refresh the page and try again',
          )
        }
        setCartState('cart')
        getCartContents()
      }
    } catch (responseErr) {
      console.log(responseErr)
      setCartState('cart')
      if (responseErr.message) {
        if (
          responseErr.message.errors &&
          responseErr.message.errors.length > 0
        ) {
          setCheckoutError(Object.values(responseErr.message.errors)[0])
        } else {
          setCheckoutError(responseErr.message)
        }
      } else {
        setCheckoutError(
          'Oh no! Something went wrong while placing your order... Please refresh the page and try again',
        )
      }
      setProcessingModal(false)
      getCartContents()
    }
  }

  const addToCartAndFetch = (product, quantity) => {
    setErrorInfo('')
    setCheckoutError('')
    setLoading(true)
    addToCart(product, quantity)
      .then(() => {
        const itemStruct = {
          id: product.wordpress_id,
          name: product.name,
          quantity,
          price: product.price,
          categories: product.categories,
        }
        const totalPrice = quantity * product.price
        analyticsAddToCart([itemStruct])
        pixelAddToCart([itemStruct], totalPrice)
        getCartMeta()
      })
      .catch(e => {
        scrollToTop()
        setLoading(false)
        setCheckoutError(e.errors && e.errors[0].detail)
        const error = `Cannot add that amount of ${product.name} to cart.`
        setErrorInfo(error)
      })
  }
  const removeFromCartAndFetch = product => {
    setCheckoutError('')
    setLoading(true)
    removeFromCart(product)
      .then(() => {
        const itemStruct = {
          id: product.product_id,
          name: product.product_name,
          quantity: product.quantity,
          price: product.product_price,
          categories: product.categories,
        }
        analyticsRemoveFromCart(itemStruct)
        getCartMeta()
      })
      .catch(e => {
        scrollToTop()
        setLoading(false)
        setCheckoutError(e.errors && e.errors[0].detail)
      })
  }

  const submitAddressAndContinue = async () => {
    // If we add shipping options, this will be the place to change them
    continueButton()
  }

  const continueButton = () => {
    scrollToTop()
    setCheckoutError('')
    if (cartState === 'cart') {
      setCartState('shipping')
      analyticsCartStepShipping(cartContents)
    } else if (cartState === 'shipping') {
      setCartState('confirm')
      analyticsCartStepPayment(cartContents)
    } else if (cartState === 'confirm') {
      setCartState('billing')
    }
  }

  const renderFreeShipping = () => {
    if (cartContents && cartContents.length > 0 && cartMeta) {
      return (
        <div
          className="ui floating icon message"
          style={{
            boxShadow: 'none',
            border: 'none',
            background: 'transparent',
          }}
        >
          <FreeShippingSvg />
          <div className="content">
            <p style={{marginLeft: '20px', fontSize: '1.4em'}}>
              You have free shipping with this order!
            </p>
          </div>
        </div>
      )
    }
    return null
  }

  const renderProductSuggestions = () => {
    if (cartContents && cartContents.length > 0 && cartMeta) {
      return (
        <div>
          <Inner>
            <CartAccessoriesList addToCart={addToCartAndFetch} />
            {errorInfo.length !== 0 && (
              <Inner>
                <Message error content={errorInfo} />
              </Inner>
            )}
          </Inner>
        </div>
      )
    }
    return null
  }

  var is_cart_empty = false
  const cartBody = (
    <>
      {cartState === 'cart' &&
        (loading || !cartReady || cartContents === null ? (
          <EllipsisLoader height="600px" />
        ) : (
          <>
            {renderFreeShipping()}
            <h2 className="top">Secure Checkout</h2>
            {cartContents.length === 0 && cartState === 'cart' ? (
              (is_cart_empty = true && (
                <>
                  <div className="message info flush">
                    <h3>Your cart is empty</h3>
                    <p class="p-text-align-center">
                      You will need to add some items to the cart before you can
                      checkout.
                    </p>
                  </div>
                  <StyledCenterButton>
                    <StyledLink to="/store/">Shop Now</StyledLink>
                  </StyledCenterButton>
                </>
              ))
            ) : (
              <>
                <CartItemList
                  items={cartContents}
                  removeFromCart={removeFromCartAndFetch}
                />
                <CartSummaryFooter>
                  <div className="text">
                    SUB-TOTAL:{' '}
                    <strong>
                      {cartMeta ? formatMoney(cartMeta.total) : formatMoney(0)}
                    </strong>
                  </div>
                  <div className="action">
                    <Button onClick={continueButton}>
                      Continue to Shipping
                    </Button>
                  </div>
                </CartSummaryFooter>
              </>
            )}
            {renderProductSuggestions()}
          </>
        ))}
      {cartState === 'shipping' &&
        (!loggedInState ? (
          <>
            <h2 className="top">Create an account</h2>
            <div className="cart-inner-container not-logged-in">
              <div className="message info">
                You need to have an account in order to continue, please
                complete the form below to get one set-up for you.
              </div>
              <RegisterForm
                registerCallback={() => {
                  console.log('Entering registerCallback()')
                  forceUseCurrentCart() // Logging in will throw away the guest cart, so add the items again
                  console.log('RegisterCallback() done')
                }}
                location={location}
              />
              <div className="login-message-row">
                Already have an account?{' '}
                <Link to="/login/" state={{fromUrl: '/cart/'}}>
                  Log in
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            {renderFreeShipping()}
            <div className="header">
              <h2 className="top">My Address</h2>
            </div>
            <div className="cart-inner-container">
              <AddressInfo
                continueCallback={submitAddressAndContinue}
                CartSummaryFooter={CartSummaryFooter}
              />
            </div>
          </>
        ))}
      {(cartState === 'confirm' || cartState === 'billing') && (
        <>
          {renderFreeShipping()}
          <div className="header">
            <h2 className="top">Proceed to Payment</h2>
          </div>
          <div className="cart-inner-container">
            <div className="cart-message-inner">
              <Payment
                location={location}
                setProcessingModal={setProcessingModal}
                givenOrderId={orderId}
                givenOrder={order}
              />
            </div>
          </div>
          <CartSummaryFooter>
            {cartState === 'confirm' && (
              <>
                <div className="text">Proceed to payment</div>
                <div className="action">
                  <Button onClick={confirmOrder}>
                    <Icon name="payment" />
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </CartSummaryFooter>
        </>
      )}
    </>
  )

  var cart_inner_class = ''
  if (is_cart_empty) {
    cart_inner_class = 'cart-empty-padding'
  }

  let is_cart_page = isOnCartPage()

  let page_class = ''
  if (is_cart_page) {
    page_class = 'cart-page'
  }

  return (
    <Layout location={location} flush>
      <CheckoutTabs cartState={cartState} setCartState={setCartState} />
      <CartPageStyles>
        <Inner className={(cart_inner_class, page_class)}>
          {/* <Message
            error
            content="We are not accepting online orders at this moment. Please contact us directly for any enquiries."
          /> */}
          {checkoutError && <Message error content={checkoutError} />}
          {cartBody}
          <LoadModal
            modalActive={processingModal}
            subtitle="Please wait while we process your order"
          />
        </Inner>
      </CartPageStyles>
    </Layout>
  )
}

export default Cart
