import React, {useState} from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

import styled from 'styled-components'
import {formatMoney} from '../../../utils'
import QuantityDropdown from '../../QuantityDropdown'

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 60px 40px;
  align-items: center;
  grid-gap: 30px;
  border-bottom: 1px solid ${props => props.theme.grey5};
  padding: 20px;
  .ui.basic.button {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    &.add:hover {
      background: ${props => props.theme.green} !important;
      color: #fff !important;
    }
  }
  .img {
    box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
    border: 1px solid ${props => props.theme.grey5};
    line-height: 0;
    min-height: 50px;
  }
  .title {
    color: ${props => props.theme.grey2};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
  }
  .meta {
    color: rgba(0, 0, 0, 0.4);
  }
  @media (max-width: 480px) {
    .img {
      grid-column: 1 / span 3;
    }
    grid-template-columns: 1fr 50px 40px;
  }
`
const CartAccessory = ({product, addCallback}) => {
  const [quantity, setQuantity] = useState(1)
  const imageSrc =
    product.images.length > 0
      ? product.images[0].src
      : '/static/Kooi-icon-blue-small.png'

  return (
    <ItemRow key={product.id}>
      <div className="img">
        <span
          style={{
            display: 'inline-block',
            height: '100px',
            verticalAlign: 'middle',
          }}
        />
        <img
          style={{position: 'relative', verticalAlign: 'middle'}}
          src={imageSrc}
          alt={product.name}
        />
      </div>
      <div>
        <div className="title">{product.name}</div>
        <div className="meta">{`${formatMoney(product.price)}`}</div>
      </div>
      <QuantityDropdown quantity={quantity} setQuantity={setQuantity} />
      <Button
        size="small"
        basic
        icon="add"
        className="add"
        onClick={() => addCallback(product, quantity)}
      />
    </ItemRow>
  )
}
export default CartAccessory
