import React from 'react'
import Step from 'semantic-ui-react/dist/commonjs/elements/Step'

import styled from 'styled-components'
import {Inner} from '../../styles'
import IconShoppingCart from '../../Icons/IconShoppingCart'
import IconShipping from '../../Icons/IconShipping'
import IconBilling from '../../Icons/IconBilling'
import IconCheck from '../../Icons/IconCheck'

const CartTabsStyles = styled.div`
  background: ${props => props.theme.white};
  padding: 20px 0 0 0;
  .ui.steps {
    .step {
      justify-content: flex-start;
      padding: 25px;
      .content {
        margin-left: 20px;
        @media (max-width: 768px) {
          margin: 10px 0 5px 0;
        }
        .title {
          color: #73718e;
          text-transform: uppercase;
        }
        .description {
          color: #73718e;
        }
      }
      &.active {
        background: ${props => props.theme.red};
        border-color: ${props => props.theme.red};
        .content {
          .title {
            color: ${props => props.theme.white};
          }
          .description {
            color: ${props => props.theme.white};
          }
        }
        .custom-icon path {
          fill: ${props => props.theme.white};
        }
        &:after {
          background: ${props => props.theme.red};
        }
      }
      &.disabled {
        background: #bebecb;
        border-color: #a8a8bd;
        &:after {
          background: #bebecb;
          border-color: #a8a8bd;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .ui.steps {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .step {
        border-radius: 0;
        &:first-child {
          border-radius: 3px 0 0 3px;
          padding: 25px 10px 15px 10px;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
          &::after {
            display: none !important;
          }
        }
        &::after {
          display: block !important;
          position: absolute;
          z-index: 2;
          content: '';
          top: 50%;
          right: 0;
          border: medium none;
          background-color: #fff;
          width: 1.14285714em;
          height: 1.14285714em;
          border-style: solid;
          border-color: rgba(34, 36, 38, 0.15);
          border-width: 0 1px 1px 0;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .ui.steps {
      .step {
        padding: 25px 10px 15px 10px;
        font-size: 14px;
        .description {
          display: none;
        }
      }
    }
  }
`

const CheckoutTabs = ({cartState, setCartState}) => {
  // Initialise with button clicks disabled
  let cartStep = {disabled: true}
  let shippingStep = {disabled: true}
  let paymentStep = {disabled: true}

  // Customise clickable state and completed-arrows according to state
  if (cartState === 'cart') {
    cartStep = {active: true}
  } else if (cartState === 'shipping') {
    cartStep = {
      completed: true,
      onClick: () => {
        setCartState('cart')
      },
    }
    shippingStep = {active: true}
  } else if (cartState === 'confirm') {
    cartStep = {
      completed: true,
      onClick: () => {
        setCartState('cart')
      },
    }
    shippingStep = {
      completed: true,
      onClick: () => {
        setCartState('shipping')
      },
    }
    paymentStep = {active: true}
    // At billing step there is no going back
  } else if (cartState === 'billing') {
    cartStep = {
      completed: true,
    }
    shippingStep = {
      completed: true,
    }
    paymentStep = {active: true}
  }

  return (
    <CartTabsStyles id="checkout-tabs">
      <Inner>
        <Step.Group fluid>
          <Step {...cartStep}>
            {cartStep.completed ? <IconCheck /> : <IconShoppingCart />}
            <Step.Content>
              <Step.Title>Cart</Step.Title>
              <Step.Description>Review your Order</Step.Description>
            </Step.Content>
          </Step>
          <Step {...shippingStep}>
            {shippingStep.completed ? <IconCheck /> : <IconShipping />}
            <Step.Content>
              <Step.Title>Shipping</Step.Title>
              <Step.Description>Shipping Details</Step.Description>
            </Step.Content>
          </Step>
          <Step {...paymentStep}>
            {paymentStep.completed ? <IconCheck /> : <IconBilling />}
            <Step.Content>
              <Step.Title>Payment</Step.Title>
              <Step.Description>Process Payment</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      </Inner>
    </CartTabsStyles>
  )
}

export default CheckoutTabs
