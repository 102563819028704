import React from 'react'

const IconBilling = ({fill}) => {
  return (
    <div className="custom-icon">
      <svg
        width="34px"
        height="27px"
        viewBox="0 0 34 27"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Menu"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M31.0624268,0.625058115 C32.6151575,0.625058115 33.8749201,1.88482074 33.8749201,3.43755141 L33.8749201,24.0625022 C33.8749201,25.6152329 32.6151575,26.8749955 31.0624268,26.8749955 L2.93749386,26.8749955 C1.38476319,26.8749955 0.125000566,25.6152329 0.125000566,24.0625022 L0.125000566,3.43755141 C0.125000566,1.88482074 1.38476319,0.625058115 2.93749386,0.625058115 L31.0624268,0.625058115 Z M2.93749386,2.50005364 C2.42188153,2.50005364 1.9999961,2.92193908 1.9999961,3.43755141 L1.9999961,6.2500447 L31.9999246,6.2500447 L31.9999246,3.43755141 C31.9999246,2.92193908 31.5780391,2.50005364 31.0624268,2.50005364 L2.93749386,2.50005364 Z M31.0624268,25 C31.5780391,25 31.9999246,24.5781146 31.9999246,24.0625022 L31.9999246,11.8750313 L1.9999961,11.8750313 L1.9999961,24.0625022 C1.9999961,24.5781146 2.42188153,25 2.93749386,25 L31.0624268,25 Z M11.3749737,20.0781367 L11.3749737,20.5468856 C11.3749737,20.9335806 11.0585454,21.2500089 10.6718504,21.2500089 L6.45311048,21.2500089 C6.06641554,21.2500089 5.74998716,20.9335806 5.74998716,20.5468856 L5.74998716,20.0781367 C5.74998716,19.6914418 6.06641554,19.3750134 6.45311048,19.3750134 L10.6718504,19.3750134 C11.0585454,19.3750134 11.3749737,19.6914418 11.3749737,20.0781367 Z M22.6249469,20.0781367 L22.6249469,20.5468856 C22.6249469,20.9335806 22.3085185,21.2500089 21.9218236,21.2500089 L13.9530926,21.2500089 C13.5663977,21.2500089 13.2499693,20.9335806 13.2499693,20.5468856 L13.2499693,20.0781367 C13.2499693,19.6914418 13.5663977,19.3750134 13.9530926,19.3750134 L21.9218236,19.3750134 C22.3085185,19.3750134 22.6249469,19.6914418 22.6249469,20.0781367 Z"
            id=""
            fill={fill || '#73718E'}
            fillRule="nonzero"
          />
        </g>
      </svg>
    </div>
  )
}
export default IconBilling
